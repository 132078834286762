
/* 
	Should contain styles that apply to the component itself 
	and no changes to the components that it will include! 
*/


$app_search-input-height: 40px;
$app_search-input-radius: floor(($app_search-input-height - 5) / 2);


.form-group {
	&.form-group-nice { @include border-radius($app_search-input-radius); display: inline-block; position: relative; background-color: $white; border: 3px solid $app_purple; width: 264px; height: $app_search-input-height; overflow: hidden;
		.form-control { @include border-radius($app_search-input-radius - 3); @include box-shadow(inset 0px 3px 1px 0px rgba(0, 0, 0, 0.25)); display: block; background-color: $white; border: none; }
	}
}

.form-login, .form-register, .form-reset-password { text-align: center;

	a { color: $app_gray3;
		&:hover, &:active, &:focus { color: inherit; }
	}
	.form-group { margin-bottom: 5px; }

	.checkbox { display: inline-block; }
}


.btn-purple { @include border-radius($app_search-input-radius); background-color: $app_purple; border: none; width: 264px; height: $app_search-input-height;
	&:hover, &:active, &:focus { background-color: darken($app_purple, 5%); }
}

