
/* 
	Should contain styles that apply to the component itself 
	and no changes to the components that it will include! 
*/

ul.dropdown-menu li, button { // cases where the icon needs spacing ...
	i.fa { margin-right: 10px; }
}

// Icons
// -------------------------
$icon-size: 24px;
$icon-block-size: 34px;
$icon-profile-size: 14px;
span.icon, i.icon { background: url(../img/icons.png) no-repeat; display: inline-block; width: $icon-size; height: $icon-size; vertical-align: middle;
	&.icon-playnow { background-position: 0 0; width: 103px; height: 40px; 
		&:hover { background-position: -103px 0; }
	}
	&.icon-like { background-position: 0 -70px; }
	&.icon-dislike { background-position: -$icon-size -70px; }
	&.icon-heart { background-position: -$icon-size*2 -70px; }
	&.icon-comment-black { background-position: -$icon-size*3 -70px; }
	&.icon-comment { background-position: -$icon-size*4 -70px; }
	&.icon-checked { background-position: -$icon-size*5 -70px; }

	&.icon-favorite { background-position: -$icon-size*6 -70px; }
	&.icon-best { background-position: -$icon-size*7 -70px; }
	&.icon-new2 { background-position: -$icon-size*8 -70px; }

	
	&.icon-gear { background-position: 0 -94px; width: $icon-block-size; height: $icon-block-size; }
	&.icon-megaphone { background-position: -$icon-block-size*1 -94px; width: $icon-block-size; height: $icon-block-size; }
	&.icon-eye { background-position: -$icon-block-size*2 -94px; width: $icon-block-size; height: $icon-block-size; }
	&.icon-camera { background-position: -$icon-block-size*3 -94px; width: $icon-block-size; height: $icon-block-size; }
	&.icon-award { background-position: -$icon-block-size*4 -94px; width: $icon-block-size; height: $icon-block-size; }

	&.icon-profile-empty { background-position: 100px 100px; width: $icon-profile-size; height: $icon-profile-size; }
	&.icon-profile-signup { background-position: 0 -128px; width: $icon-profile-size; height: $icon-profile-size; }
	&.icon-profile-plays { background-position: -$icon-profile-size*1 -128px; width: $icon-profile-size; height: $icon-profile-size; }
	&.icon-profile-facebook { background-position: -$icon-profile-size*2 -128px; width: $icon-profile-size; height: $icon-profile-size; }
	&.icon-profile-country { background-position: -$icon-profile-size*3 -128px; width: $icon-profile-size; height: $icon-profile-size; }
	&.icon-profile-gender { background-position: -$icon-profile-size*4 -128px; width: $icon-profile-size; height: $icon-profile-size; }
	&.icon-profile-birthday { background-position: -$icon-profile-size*5 -128px; width: $icon-profile-size; height: $icon-profile-size; }
	&.icon-profile-favorite { background-position: -$icon-profile-size*6 -128px; width: $icon-profile-size; height: $icon-profile-size; }
	&.icon-profile-comments { background-position: -$icon-profile-size*7 -128px; width: $icon-profile-size; height: $icon-profile-size; }
	
	&.icon-person { background-position: 0 -142px; width: 44px; height: 44px; }
}

// Social
// -------------------------
$icon-social-size: 29px;
span.social, i.social { background: url(../img/icons.png); display: inline-block; width: $icon-social-size; height: $icon-social-size; vertical-align: middle;
	&.social-facebook { background-position: 0 -41px; }
	&.social-google { background-position: -$icon-social-size -41px; }
	&.social-youtube { background-position: -$icon-social-size*2 -41px; }
	&.social-twitter { background-position: -$icon-social-size*3 -41px; }

	&.social-facebook-color { background-position: -$icon-social-size*4 -41px; }
	&.social-google-color { background-position: -$icon-social-size*5 -41px; }
	&.social-twitter-color { background-position: -$icon-social-size*6 -41px; }
}

