
/* 
	Should contain styles that apply to the component itself 
	and no changes to the components that it will include! 
*/

div.game { margin-bottom: $grid-gutter-width - 5;
	> a:first-of-type { @include aspect-ratio(180, 135); display: block; overflow: hidden;
		.thumb { position: absolute; top: 0; left: 0; right: 0; bottom: 0; display: block; width: 100%; height: 100%; }

		.info { position: absolute; bottom: 0; padding-bottom: 10px; width: 100%; line-height: 1.3; text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
			&:before { content: ""; display: block; position: absolute; bottom: 0; background: url(../img/fade.png) repeat-x bottom left; width: 100%; min-height: 140px; }
			.title, .desc {/*  @include text-overflow(); */ display: block; position: relative; z-index: 1; padding: 0 6px; vertical-align: middle; }
			.title { color: $white; font-size: 16px; font-weight: 700; }
			.desc { padding-right: 20px; color: $white; font-size: 15px; }
		}

		&:hover {
			&:after { content: ""; position: absolute; top: 0; left: 0; z-index: 2; display: block; background: url(../img/playnow.png) no-repeat center center rgba(0, 0, 0, 0.2); width: 100%; height: 100%; }
		}

		@media screen and (max-width: 1200px) and (min-width: 992px) {
			.info {
				a.title { font-size: 13px; }
				.desc { font-size: 11px; padding-right: 6px; }
			}
		}
	}

	> .info { line-height: 1.3;
		a.title, .desc { overflow: hidden; display: block; vertical-align: middle; }
		a.title { @include text-overflow(); color: $app_purple; font-size: 16px; font-weight: 700; line-height: 50px;
			&:hover { color: darken($app_purple, 20%); text-decoration: none; }
		}
		.desc { height: 60px; padding-bottom: 6px; margin-bottom: 14px; color: $app_gray3; font-size: 15px; }

		@media screen and (max-width: 1200px) and (min-width: 992px) {
			a.title { font-size: 16px; }
		}
	}

	a.favorite { position: relative; display: inline-block; color: $app_gray3;
		&:before { content: "\f005"; font-family: FontAwesome; font-style: normal; font-weight: normal; text-decoration: inherit; color: inherit; }
		&:hover { color: $app_purple; text-decoration: none; }
		&.active, &:focus, &:active { color: #FF6C00; text-decoration: none; }
	}
	> a.favorite { position: absolute; z-index: 3; bottom: 60px; right: 12px; font-size: 20px; }

	.stats { position: relative; @include clearfix(); @include border-radius(6px); background-color: $app_gray2; margin-top: 6px; overflow: hidden; color: #AEAEAE; font-size: 16px;
		> a, > div { position: relative; display: block; float: left; color: inherit; padding: 9px 8px; overflow: hidden;
			&:before { font-family: FontAwesome; font-style: normal; font-weight: normal; text-decoration: inherit; }
			&:hover, &:active, &:focus { color: $app_purple; text-decoration: none; }
		}
		.likes { @include vertical-line-after(1px, 40%);
			&:before { content: "\f164"; margin-right: 6px; }
			&:hover { color: #39b88c; }
		}
		.dislikes {
			&:before { content: "\f165"; margin-right: 6px; }
			&:hover { color: #fc5e55; }
		}
		a.favorite { position: absolute; top: 0; right: 0; }
		
		@media screen and (max-width: 1200px) and (min-width: 992px) {
			.likes, .dislikes { width: 28px; }
		}

		// @media screen and (min-width: $screen-lg) {
		// 	a.favorite { position: relative; top: auto; right: auto; float: right; }
		// }
	}
	> .stats-wrapper { position: relative; padding-right: 106px;
		a.playnow { display: block; position: absolute; top: 0; right: 0; }
		.stats {
			> div { width: 50%; }
		}
	}



	/* Styles */
	&.style-large {
		> a:first-of-type {
			.info { padding-bottom: 20px;
				.title { padding-left: 20px; font-size: 26px; }
				.title, .desc { padding-left: 20px; }
			}

			&:hover {
				&:after { display: none; }
			}

			@media screen and (min-width: $screen-sm) {
				.info { padding-right: 134px;
					&:after { content: ""; display: block; position: absolute; right: -16px; bottom: -12px; background: url(../img/playnow.png) no-repeat center center; width: 170px; height: 94px; }
				}
			}
		}
	}
}

.game-container { @include clearfix;
	.game { width: 50%; float: left; padding: $grid-gutter-width / 2; }

	@media screen and (min-width: $screen-md) {
		.game { width: auto; float: none; }
	}
}

.game-info {
	.tab-pane {
		p { padding: 10px; color: $app_gray3; }
	}
}
