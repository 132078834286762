@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	border-radius: $radius;
	background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

@mixin border-top-radius($radius) {
	-webkit-border-top-right-radius: $radius;
	border-top-right-radius: $radius;
	-webkit-border-top-left-radius: $radius;
	border-top-left-radius: $radius;
	background-clip: padding-box;
}
@mixin border-right-radius($radius) {
	-webkit-border-bottom-right-radius: $radius;
	border-bottom-right-radius: $radius;
	-webkit-border-top-right-radius: $radius;
	border-top-right-radius: $radius;
	background-clip: padding-box;
}
@mixin border-bottom-radius($radius) {
	-webkit-border-bottom-right-radius: $radius;
	border-bottom-right-radius: $radius;
	-webkit-border-bottom-left-radius: $radius;
	border-bottom-left-radius: $radius;
	background-clip: padding-box;
}
@mixin border-left-radius($radius) {
	-webkit-border-bottom-left-radius: $radius;
	border-bottom-left-radius: $radius;
	-webkit-border-top-left-radius: $radius;
	border-top-left-radius: $radius;
	background-clip: padding-box;
}

@mixin text-overflow() {
	white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
}

@mixin aspect-ratio($width, $height) {
	position: relative;
	&:before  {display: block; content: ""; width: 100%; padding-top: ($height / $width) * 100%; }
	// > .content { position: absolute; top: 0; left: 0; right: 0; bottom: 0; }
}

@mixin vertical-line-after($width: 1px, $height: 100%, $color: rgba(0, 0, 0, 0.2)) { position: relative;
	&:after { content: ""; position: absolute; right: 0; top: (100% - $height) / 2; display: block; background: $color; width: $width; height: $height; cursor: inherit; }
}

@mixin vertical-align($position: relative) {
	position: $position;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}