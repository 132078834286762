
/* 
	Should contain styles that apply to the component itself 
	and no changes to the components that it will include! 
*/

.alert { @include border-radius(6px);  margin: 0 10px 15px 10px;
	&.size-sm { padding: 4px; margin: 0; }
	&.body-alert { z-index: 9; position: fixed; top: 10px; right: 30px; padding-top: 10px; padding-bottom: 8px; margin: 0; }
}
