
/* 
	Should contain styles that apply to the component itself 
	and no changes to the components that it will include! 
*/

.blog-post { padding: 20px 0; border-bottom: 1px solid #DCDCDC;
	.blog-post-image { @include aspect-ratio(468, 386); display: block; overflow: hidden;
		img { position: absolute; top: 0; left: 0; right: 0; bottom: 0; display: block; width: 100%; height: 100%; }
	}
	.blog-post-content { }

	.blog-post-title { position: relative; padding-top: 10px; padding-bottom: 5px; margin-top: 0; margin-bottom: 0; color: $app_purple; font-size: 21px; font-weight: 400; line-height: 1.2; vertical-align: bottom;
		> a { color: inherit; text-decoration: none; font-weight: 700;
			&:hover, &:focus { color: inherit; text-decoration: underline; }
		}
		@media (min-width: $screen-lg-min) { height: 100px; padding-top: 0; padding-bottom: 10px; margin-bottom: 10px;
			> a { position: absolute; bottom: 0; }
		}
	}
	.blog-post-info { font-size: 12px; font-weight: 200; }
	.blog-post-body, > .blog-post-excerpt {
		&, > p { font-size: 15px; font-weight: 400; }
	}
	.blog-post-excerpt {
		a.more { color: $app_purple; font-size: 14px; font-weight: 700; }
	}

	&.single {
		.blog-post-image { max-width: 468px; }
		.blog-post-title { height: auto; }
	}
}
