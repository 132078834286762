
/* 
	Should contain styles that apply to the component itself 
	and no changes to the components that it will include! 
*/


h1, h2, h3 { font-weight: 700; }

.title-wrapper { position: relative; display: block; border-bottom: 1px solid #DCDCDC; margin-bottom: 10px;
	h1, h2, h3 { position: relative; display: inline-block; padding: 0 0 6px 0; margin: 0; color: $app_purple; font-size: 18px;
		&:after { position: absolute; bottom: -1px; content: ""; display: block; background-color: $app_purple; width: 100%; height: 2px; }
	}

	&.size-sm { padding-top: 16px;
		h1, h2, h3 { font-size: 18px;
			&:after { display: none; }
		}
	}

	a.more { @include vertical-align(absolute); right: 0; color: $app_purple; font-size: 0;
		&:after { display: inline-block; content: "\f054"; padding: 5px 10px; font-size: 16px; font-family: FontAwesome; font-style: normal; font-weight: normal; text-decoration: inherit; vertical-align: middle; }
		&:hover { color: darken($app_purple, 20%); text-decoration: none; }
	}

	@media (min-width: 370px) {
		h1, h2, h3 { font-size: 22px; }
		a.more { font-size: 16px; font-weight: 700;
			&:after { padding: 0 0 0 4px; }
		}
	}

	@media (min-width: 420px) { margin-bottom: 18px;
		h1, h2, h3 { padding: 0 0 14px 0; font-size: 25px; }
	}
}

h3 { color: $app_purple; }