
/* 
	Should contain styles that apply to the component itself 
	and no changes to the components that it will include! 
*/


$mm-height: 62px;

.main-header { margin-bottom: 15px; position: relative;
	.container-fluid { padding: 0; margin: 0; }
	.nav {
		> li {
			> a, > button { float: left; padding: 10px; color: $app_purple; font-size: 16px; font-weight: 700; line-height: 20px; text-align: center;
				.current-language { text-transform: uppercase; }
				&.dropdown-toggle { position: relative; padding-right: 26px;
					&:before { content: "\f0d7"; position: absolute; right: 10px; display: block; font-family: FontAwesome; }
				}

				&.avatar-container { position: relative; padding-left: 36px;
					span.avatar { @include border-radius(50%); position: absolute; /* top: 5px; */ top: $mm-height / 2 - 15; left: 8px; display: inline-block; background: $app_gray2; border: 2px solid $white; width: 31px; height: 31px; padding: 0; line-height: 31px; text-align: center; overflow: hidden;
						img { width: 100%; height: 100%; line-height: 100%; vertical-align: top; }
						i.fa {
							&.fa-user { font-size: 34px; color: darken($app_gray2, 10%); }
						}
					}
				}
				&:hover, &:focus, &:active { background: rgba(255, 255, 255, 0.2); }
			}
			&.user-account { display: none; }
		}
		&.nav-lg {
			> li > a { font-size: 18px; }
		}

		
		@media (min-width: $grid-float-breakpoint) {
			> li {
				> a, > button { padding-top: 21px; padding-bottom: 21px;
					&.avatar-container { padding-left: 46px;
						span.avatar { top: $mm-height / 2 - 15; }
					}
					span.name { display: none; }
				}
				&.active {
					> a { overflow: hidden;
						&:after { @include border-radius(6px); content: ""; position: absolute; left: 0; bottom: -6px; display: block; background: $app_purple; width: 100%; height: 12px; }
					}
				}
				&.user-account { display: block; }
			}
		}
	}

	form.search { width: 38%; min-width: 180px; padding: 10px 4px;
		$app_search-input-height: 35px;
		$app_search-input-radius: 20px;

		.input-group-search { @include border-radius($app_search-input-radius); position: relative; background-color: $white; border: 3px solid $app_purple; width: 100%; height: $app_search-input-height; overflow: hidden;
			.form-control { @include border-radius($app_search-input-radius - 3); box-shadow: inset 0px 3px 1px 0px rgba(0, 0, 0, 0.25); display: block; border: none; background-color: $white; }
			.input-group-btn { display: block; position: absolute; z-index: 3; right: 0; width: 38px; height: 100%;
				.btn { background: transparent; border: none; padding: 6px 14px 6px 6px; color: $app_purple; font-size: 21px; line-height: $line-height-computed;
					i.fa { font-weight: 700; margin-right: 0; }
					&:hover, &:focus, &:active { background: inherit; color: darken($app_purple, 10%); }
				}
			}
		}
	}

	.main-collapse {
		@media (min-width: $grid-float-breakpoint) {
			&.collapse { display: none; }
		}
	}

	header { position: relative; background: url(../img/header.png) repeat-x bottom left $yellow-base; border-bottom: 8px solid $app_purple; min-height: $mm-height; padding: 0;
		> .container {
			@media (max-width: $grid-float-breakpoint + 200px) { width: 100%!important; padding: 0; }
		}

		button.btn-mobile, button.btn-search, button.btn-toggle { display: block; background-color: transparent; border: none; color: $app_purple; font-size: 28px;
			i.fa { margin-right: 0; }
			&:focus { outline: 0; }
		}

		button.btn-mobile, button.btn-toggle { padding: 11px 22px;
			.fa { font-weight: 400; }
			&:hover, &:focus, &:active { background: rgba(255, 255, 255, 0.2); }
		}

		button.btn-mobile { position: absolute; top: 0; right: 0; z-index: 9999; }
		button.btn-search { padding-top: 17px; padding-bottom: 17px; padding-left: 22px; padding-right: 22px;
			@media (min-width: $screen-md-min + 100px) { display: none; }
		}

		.btn-mobile {
			@media (min-width: $grid-float-breakpoint) {
				display: none!important;
			}
		}

		.mobile {
			@media (min-width: $grid-float-breakpoint + 200px) {
				display: none!important;
			}
		}
		.mobile-not {
			@media (max-width: $grid-float-breakpoint + 200px) {
				 display: none!important;
			}
		}

		.logo { @include make-sm-column(3); @include make-md-column(2); @include make-lg-column(2);
			position: relative; display: block; height: $mm-height; padding: 0;
			a { display: block; position: absolute; z-index: 9999; left: 50%; margin-left: -75px; bottom: -58px; width: auto; text-align: left;
				img { display: inline-block; width: 150px; height: 110px; }
			}

			@media (min-width: $grid-float-breakpoint) {
				a { left: 0; margin-left: 0; right: 16px; text-align: center; }
			}
		}
		
		.navs { @include make-sm-column(9); @include make-md-column(10); @include make-lg-column(10);
			padding: 0; min-height: 0;
			> .left { display: none; }
			> .right { @include clearfix;
				> .search { float: right; display: none; }
				> .nav { float: right;
					> li { float: left; }

					&.user-menu { position: absolute; top: -$mm-height; left: 0;
						> li {
							> a { padding-top: 21px; padding-bottom: 21px; }
						}
					}
				}
			}

			@media (min-width: $grid-float-breakpoint) {
				.nav {
					&.nav-lg {
						> li > a { padding-left: 20px; padding-right: 20px; font-size: 22px; }
					}
				}

				> .left { display: block; }
				> .right { float: right; width: auto;
					> .nav {
						&.user-menu { position: relative; top: auto; right: auto; }
					}
				}
			}
			@media (min-width: $screen-md-min + 100px) {
				> .right {
					.search { display: block; }
				}
			}
		}
	}

	#search-menu { position: absolute; top: 0; bottom: 8px; left: 0; right: 0; z-index: 99990; background-color: $yellow-base;
		> * { display: inline-block; vertical-align: middle; }
		form.search { width: 100%; padding: 11px 4px; padding-right: 66px; }
		.btn-toggle { position: absolute; right: 0; top: 0; background-color: transparent; border: none; padding: 11px 22px; color: $app_purple; font-size: 28px;
			.fa { font-weight: 400; margin-right: 0; }
			&:hover, &:focus, &:active { background: rgba(255, 255, 255, 0.2); }
		}
	}

	@media (min-width: $screen-md-min + 100px) {
		#search-menu { display: none; }
	}
}
