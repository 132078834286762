// Flags
// -------------------------
// span.flag { background: url(../img/flags.png); display: inline-block; width: 22px; height: 18px; vertical-align: middle;
// 	&.flag-en { background-position: 0 0; }
// 	&.flag-de { background-position: 0 -18px; }
// }


// Ads
// -------------------------
.ad-sample { /* background-color: $yellow-base; */ width: 728px; height: 90px; line-height: 90px; margin-bottom: 15px; font-size: 26px; font-weight: 400; text-align: center;
	// &:before { content: '500 FREE ON LINE GAMES PER MONTH' }

	&.fluid { width: 100%; height: auto; min-height: 90px; }
	&.fluid-h600 { width: auto; height: 600px; line-height: 1.5; }
	&.fluid-h280 { width: auto; height: 280px; padding-top: 50px; line-height: 1.5; }
	&.160x600 { width: 160px; height: 600px; line-height: 1.1; }
}


// Images
// -------------------------
$avatar-size: 120px;
img.avatar-circle { width: $avatar-size; height: $avatar-size; line-height: $avatar-size; border-radius: 50%; }

// Profile
// -------------------------
.profile-avatar { padding: 10px; text-align: center;
	img { margin-bottom: 10px; }
}

// General
// -------------------------
.no-margin { margin: 0!important; margin-top: 0!important; margin-bottom: 0!important; margin-left: 0!important; margin-right: 0!important; }
.circle { overflow: hidden; border-radius: 50%; }

.main-wrapper { height: 100vh; }

ul.list-icons, ol.list-icons { list-style-type: none; padding: 0;
	> li { color: $app_gray3; font-size: 16px; font-weight: 500; line-height: 1.8; vertical-align: middle;
		span.icon, i.icon { margin-right: 6px; }
	}
}

a.game-prop { @include vertical-line-after(); display: inline-block; padding-right: 10px; margin: 15px 5px; color: $app_gray-medium; font-size: 16px; font-weight: 400; font-family: $font-family-serif; line-height: 24px; vertical-align: middle; cursor: default;
	i { margin-right: 4px; font-size: 21px; vertical-align: top; cursor: inherit;
		&.fa-thumbs-up { color: #39b88c; }
		&.fa-thumbs-down { color: #fc5e55; }
		&.fa-comment { color: #d91965; }
		// &.fa-star { color: #ff6c00; }
	}

	&:hover { text-decoration: none; color: $app_purple; cursor: pointer; }

	&.favorite {
		i {
			&.fa-star { color: $app_gray3; }
		}
		&:hover, &.active {
			i {
				&.fa-star { color: #ff6c00; }
			}
		}
	}
}

.no-entries { padding: 10px; margin: 0; font-size: 13px; text-align: center; }

#scrollup { @include border-radius(6px); display: none; z-index: 9; position: fixed; bottom: 40px; right: 40px; background: $yellow-base; width: 36px; height: 36px; color: $app_purple; font-size: 24px; text-align: center; vertical-align: middle;
	&:before { content: "\f077"; font-family: FontAwesome; display: inline-block; text-decoration: none; color: inherit; }
	&:hover { background: lighten($yellow-base, 10%); text-decoration: none; }
}

.main-header, .side-menu {
	.dropdown-menu { @include border-radius(6px); top: 90%; background: $app_purple; border-width: 0; min-width: 100%; padding: 8px 0;
		> li {
			> a { font-weight: 700; color: $white; font-weight: 700;
				&:hover, &:focus, &:active { background-color: $yellow-base; color: $white; }
			}

			&.divider { background-color: rgba($white, 0.65); height: 1px; }
		}

		@media (min-width: $grid-float-breakpoint) {  }
	}
}

.row-horizontal {
	@media (max-width: $grid-float-breakpoint) { width: 100%; height: 332px; overflow-x: auto; white-space: nowrap;
		> div { display: inline-block; float: none; width: 200px; white-space: normal; }
	}
}

.no-gutter {
	@media (max-width: $grid-float-breakpoint) { margin-left: -1 * ($grid-gutter-width / 2); margin-right: -1 * ($grid-gutter-width / 2); padding-bottom: $grid-gutter-width / 2;
		.row { margin-left: 0; margin-right: 0;
			.game { padding: 0; margin: 0;
				&.style-large {
					.info {
						.title, .desc { padding-left: 10px; }
						.desc { max-height: none; font-size: 15px; }
					}
				}
				.info {
					.desc { max-height: 30px; font-size: 12px; }
				}
			}
			> div { padding: 0;
				> .row { margin-left: 0; margin-right: 0;
					> div { padding: 0;
						&:nth-child(n+7) { display: none; }
					}
				}
			}
		}
	}
}

#Veediframe {
	@media (max-width: 926px) { width: 100%; }
}


