
/* 
	Should contain styles that apply to the component itself 
	and no changes to the components that it will include! 
*/


.side-menu { display: none; position: fixed; left: 0; right: 0; top: 0; bottom: 0; z-index: 99991; background: rgba($black, .8);
	.inner { @include box-shadow(-3px 0 18px 3px rgba($black, 0.5)); position: fixed; right: 0; top: 0; bottom: 0; background: white; width: 280px; overflow: auto;
		.cont { padding: 10px 20px; }

		.navs { width: 100%; padding: 0; margin: 0;
			> .left { display: block; }
		}
	}


	.user-account { list-style-type: none; padding: 0; margin: 0 0 20px 0;
		li {
			a {
				&.avatar-container { position: relative;
					span.avatar { @include border-radius(50%); @include box-shadow(0 2px 6px 0 rgba($black, 0.1)); display: block; background: $app_gray2; border: 3px solid $yellow-base; width: 71px; height: 71px; padding: 0; margin: 0 auto; line-height: 71px; text-align: center; overflow: hidden;
						img { width: 100%; height: 100%; line-height: 100%; vertical-align: top; }
						i.fa {
							&.fa-user { font-size: 74px; color: darken($app_gray2, 10%); }
						}
					}
					span {
						&.mobile { display: none; }
						&.mobile-not { display: block; padding: 5px 0 0 0; color: $app_purple; font-size: 18px; font-weight: 700; line-height: 20px; text-align: center;
							&:after { content: "\f0d7"; display: inline-block; padding: 0 6px; font-family: FontAwesome; }
						}
					}
				}
				&:hover, &:active, &:focus { text-decoration: none; }
			}
			.dropdown-menu-right { left: 0; }
			.dropdown-menu { top: 100%; }
		}
	}

	// @media screen and (min-width: $screen-xs) {
	// 	.inner { width: 280px; }
	// }

	@media screen and (min-width: $grid-float-breakpoint) { display: none!important; }

}
