
/* 
	Should contain styles that apply to the component itself 
	and no changes to the components that it will include! 
*/

.panel { border: none;  @include box-shadow(none);
	> .panel-heading { @include clearfix; @include border-top-radius(6px); background: url(../img/panel-top-right.png) no-repeat top right $app_purple; border: 0; padding: 10px 58px 10px 14px;
		> .panel-title { color: $white; font-weight: 700;
			span.icon, i.icon { margin-right: 15px; }
			i.fa { @include vertical-line-after(); position: relative; color: $yellow-base; padding-right: 10px; margin-right: 10px; font-size: 21px; vertical-align: middle; }
		}
		> .panel-extra { float: right; color: $white; font-size: 17px;
			i { }
		}
	}

	> .panel-footer { border-top: none; @include border-bottom-radius(6px); padding: 6px 0 0 0; }


	&.panel-gray { background-color: $app_gray2;
		> .panel-heading {
			> .panel-title { }
		}
		> .panel-body { padding: 0; }
	}
	&.panel-naked { background-color: transparent; border: 0; }
	&.panel-round { @include border-radius(6px);
		.panel-body { padding: 40px 5px;
			.form-group:last-of-type { margin-bottom: 0; }
		}
	}

	&.panel-blue {
		> .panel-heading { background-color: $app_blue; border: 0;
			> .panel-title { color: $white; font-size: 19px; }
		}
	}

	&.panel-inside-heading { @include border-radius(6px); padding: 10px;
		> .panel-body { padding: 0;
			> .panel-inside-heading { @include clearfix;
				> h1, > h2, > h3 { float: left; margin: 10px 0 10px 15px; color: $app_purple; font-size: 24px; font-weight: 700; line-height: 33px; vertical-align: middle; }
				> .extra { float: right; vertical-align: middle; line-height: 33px;
					> * { vertical-align: middle; }
					> .sharing { display: inline-block; vertical-align: top; padding-top: 4px; }
				}
			}
		}
	}

	&.panel-game { @include border-bottom-radius(0); background-color: $app_gray2; margin-bottom: 0; }
	&.panel-comments, &.panel-howto {
		&.panel-inside-heading {
			> .panel-body { padding: 0;
				> .panel-inside-heading { border-bottom: 1px solid #DCDCDC; padding: 5px; margin-bottom: 10px;
					> h1, > h2, > h3 { margin: 0; font-size: 18px; line-height: 24px; }
					> .extra { line-height: 24px; }
				}
			}
		}
	}
}

.panel-title { font-size: 18px; @include text-overflow; }

.similar { background-color: $app_gray2;
	.panel { padding-bottom: 0; margin-bottom: 0;
		> .panel-body { padding: 0; }

		&.panel-inside-heading {
			> .panel-body { padding: 0;
				> .panel-inside-heading {
					> h1, > h2, > h3 { margin: 10px 0; font-size: 28px; line-height: 32px; }
				}
			}
		}
	}
}

