
/* 
	Should contain styles that apply to the component itself 
	and no changes to the components that it will include! 
*/

.pagination-wrapper { margin: 30px 0 10px 0; text-align: center; }

.pagination {
	> li {
		> a, > span { @include border-radius(6px); background-color: transparent; border: none; padding: 6px 0; margin: 0 4px; color: $app_gray3; font-size: 18px; font-weight: 700; width: 40px; text-align: center; }
		&:first-child, &:last-child {
			> a, > span { @include border-radius(6px); background-color: $app_gray2; color: $app_purple; }
		}
	}

	> li > a, > li > span {
		&:hover, &:focus { background-color: $app_gray2; color: $app_purple; }
	}

	> .active > a, > .active > span {
		&, &:hover, &:focus { color: $app_purple; background-color: transparent; border: none;
			&:after  { @include border-radius(6px); content: ""; position: absolute; left: 0; bottom: -4px; display: block; background: $app_purple; width: 100%; height: 4px; }
		}
	}

	> .disabled {
		> span, > span:hover, > span:focus, > a, > a:hover, > a:focus { color: $app_gray; }
	}
}
