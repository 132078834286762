
/* 
	Should contain styles that apply to the component itself 
	and no changes to the components that it will include! 
*/

.comment { display: block; margin-bottom: 15px;
	p, .form-control { @include border-radius(6px); @include box-shadow(inset 1px 1px 6px 0px rgba(50, 50, 50, 0.10)); background: $app_gray2; padding: 25px; font-size: 13px; font-weight: 400; text-align: left; }
	
	> div.game-name { padding: 0 0 4px 10px; font-size: 14px; color: $app_purple;
		a {
			&, &:hover, &:active { color: inherit; }
		}
	}
	
	> .comment-container { position: relative; text-align: center;
		.user { display: inline-block; margin-bottom: 10px; }
		.comment-body { display: inline-block; }
	}
	
	> .comment { padding-left: 20px; }
	

	@media (min-width: $screen-sm-min) {
		> .comment-container { @include clearfix; text-align: left;
			.user { float: left; position: absolute; left: 10px; top: 10px; }
			.comment-body { display: block; float: left; padding-left: 80px; width: 100%; }
		}
	}
}

.form-add-comment { @include clearfix; padding-bottom: 20px;
	> .comment { margin-bottom: 0; }
	> .btn-primary { float: right; margin-right: 10px; }
	textarea.form-control { border: none; max-width: 100%; }
}
