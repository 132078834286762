
/* 
	Should contain styles that apply to the component itself 
	and no changes to the components that it will include! 
*/


footer { position: relative; background: url(../img/footer.jpg) repeat-x top left #F7A63C; min-height: 192px; padding: 0;
	.brand { text-align: center;
		.copyright { color: #955401; font-size: 12px; font-weight: 400; }
	}
	ul { padding: 0;
		li { list-style-type: none; text-align: center;
			a { color: $gray-dark; font-size: 15px;
				i.fa { width: 20px; text-align: center; }
				&:hover, &.active { color: inherit; }
			}
			&.section-title { padding: 16px 0 2px 0; color: $app_purple; font-size: 18px; font-weight: 700; @include text-overflow; }
		}
	}

	&.style-gray {
		&:before { content: ""; position: absolute; top: 0; left: 0; right: 0; display: block; background-color: $app_gray2; height: 52px; }
	}

	@media (min-width: $screen-sm-min) {
		ul {
			li { text-align: left;
				&.section-title { padding-bottom: 34px; }
			}
		}
	}
	
	@media (max-width: $screen-sm-min) {
		.clear-on-xs { clear: both; }
		.social { clear: both; text-align: center;
			.icon { display: inline-block; margin: 5px 2%;
				a { @include border-radius(6px); display: block; width: 50px; padding: 10px 0; font-size: 0;
					i { width: auto; font-size: 32px; }
					&:hover, &:focus, &:active { background: rgba(255, 255, 255, 0.2); }
				}
			}
		}
	}
}
