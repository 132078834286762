
/* 
	Should contain styles that apply to the component itself 
	and no changes to the components that it will include! 
*/

.nav-tabs {
	&.nav-tabs-purple { $ntp-height: 40px;
		position: relative; background-color: $app_gray2; border-bottom: none; height: $ntp-height;

		> li { display: table-cell; width: 1%; // here because bootstrap has them just for min-width > grid-float-breakpoint
			> a { border: none; border-radius: 0; color: $app_gray3; font-weight: 700;
				&:hover, &:focus { background-color: transparent; color: $app_purple; }
			}

			&.active > a {
				&, &:hover, &:focus { background-color: $app_purple; border: none; color: $white; cursor: default;
					&:before { -webkit-transform: rotate(360deg); content: ""; position: absolute; left: 0; bottom: 2px; width: 0; height: 0; border-style: solid; border-width: $ntp-height $ntp-height + 2 0 0; border-color: $app_gray2 transparent transparent transparent; }
					&:after { -webkit-transform: rotate(360deg); content: ""; position: absolute; right: 0; bottom: 2px; width: 0; height: 0; border-style: inset; border-width: 0 $ntp-height $ntp-height + 2 0; border-color: transparent $app_gray2 transparent transparent; }

				}
			}
		}
		&:after  { @include border-radius(6px); content: ""; position: absolute; left: 1px; right: 1px; bottom: -3px; display: block; background: $app_purple; width: 100%; height: 6px; }
	}
}

.nav-pills {
	&.nav-pills-gray { color: $app_gray;
		> li { border-bottom: 1px solid #DCDCDC; padding: 0 0 0 20px;
			> a { @include text-overflow; background-color: transparent; padding: 0; color: inherit; font-size: 16px; font-weight: 700; line-height: 40px; vertical-align: middle;
				&:hover, &:focus { background-color: transparent; color: $app_purple; }
			}
			&.active {
				> a { color: $app_purple;
					&:hover, &:focus { color: $app_purple; }
				}
			}

			&.more { padding: 0; border-bottom-width: none;
				> a { padding: 10px 0; color: $app_purple; text-align: center; text-transform: uppercase; }
			}
		}
	}
	&.nav-pills-colors {
		> li { @include border-radius(6px); border: 2px solid #000; overflow: hidden; margin-bottom: 10px;
			> a { padding: 6px; line-height: 24px; color: inherit; font-size: 16px; font-weight: 700; vertical-align: middle; text-transform: uppercase;
				i.icon { position: relative; margin-right: 12px; vertical-align: middle;
					&:after { content: ""; position: absolute; right: -6px; top: 0; display: block; background: rgba(0, 0, 0, 0.1); width: 1px; height: 100%; }
				}
			}

			@mixin nav-pills-color($color) { border-color: $color; color: $color;
				> a {
					&:hover { background-color: rgba($color, 0.3); color: $color;
						// i.icon { background-position-y: -94px; }
					}
				}
				&.active {
					> a { background-color: $color; color: $white;
						i.icon { background-position-y: -94px; }
					}
				}
			}
			&.active {
				> a { background-color: $white; }
			}

			&.green { @include nav-pills-color(#00974B); }
			&.blue { @include nav-pills-color(#006CFF); }
			&.orange { @include nav-pills-color(#FF6C00); }
			
		}
	}
	&.nav-pills-tags { padding: 0; color: $app_purple;
		> div {
			> a { display: block; }
		}
		> li, > div {
			> a { border-radius: 9px; background-color: $app_gray2; padding: 5px 14px; margin: 5px 2px; color: $app_purple; font-size: 12px; font-weight: 700;
				&:hover, &:focus { background-color: $yellow-base; color: inherit; text-decoration: none; }
			}
			&.active {
				> a { color: $app_purple;
					&:hover, &:focus { color: $app_purple; }
				}
			}
		}

		&.nav-pills-circles {
			> li, > div {
				> a { padding-left: 10px; font-weight: 700; text-transform: uppercase;
					&:before { @include border-radius(50%); @include box-shadow(1px 1px 6px 0px darken($app_gray2, 10%)); content: ""; display: inline-block; background-color: $white; width: 8px; height: 8px; margin: 0 6px 2px 0; vertical-align: middle; }
				}
			}
		}
	}

	&.nav-pills-no-overflow {
		> li, > div {
			> a { @include text-overflow(); }
		}
	}
}
