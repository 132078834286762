/* General Stuff */
/* Colors */
$yellow-base: #ffcb31;
$black: #000;
$white: #fff;


$app_blue: #0089ff;
$app_gray-medium: #8b8b8b;

$app_purple: #D91965;
$app_gray: #999;
$app_gray2: #F5F5F5;
$app_gray3: #666;

/*
	General Bootstrap Variables
		List with all variables can be found here:
			node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables
*/
$body-bg: #fff;
$font-family-sans-serif:  Ubuntu, Helvetica, Arial, sans-serif;
$font-family-serif:       Ubuntu, Georgia, "Times New Roman", Times, serif !default;


$border-radius-base: 0px;
$border-radius-large: 0px;
$border-radius-small: 0px;

$wideBy: 234;

$grid-columns: 12;
$grid-gutter-width: 15px;

// make the site wider...
$grid-gutter-width:       20px;
$screen-sm:               768px;
$screen-md:               992px;
$screen-lg:               1200px;
$container-tablet:        (720px + $grid-gutter-width);
$container-desktop:       (940px + $grid-gutter-width);
$container-large-desktop: (1140px + $grid-gutter-width);

$grid-float-breakpoint: $screen-sm;

// $screen-sm:               $screen-sm + $wideBy;
// $screen-md:               $screen-md + $wideBy;
$screen-lg:               $screen-lg + $wideBy;
$container-tablet:        98%;
$container-desktop:       98%;
$container-large-desktop: $container-large-desktop + $wideBy;


$panel-default-heading-bg: $yellow-base;
$panel-footer-bg: $app_purple;

$navbar-height: 62px;

@import "bootstrap";
/* END */

/* Importing app stuff! */
@import "mixins";
@import "utilities";
@import "components/_type";
// @import "components/_grid"; // disabled because the 15 grid is not used ...
@import "components/_icon";
@import "components/_alert";
@import "components/_header";
@import "components/_footer";
@import "components/_panel";
@import "components/_navs";
@import "components/_forms";
@import "components/_pagination";
@import "components/_game";
@import "components/_comment";
@import "components/_blog-post";
@import "components/_side-menu";


